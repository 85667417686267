import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService, ApiSingleResult } from '@capturum/api';
import { QrCode } from '@core/models/qr-code.model';
import { Observable } from 'rxjs';
import { Orientation } from '@core/enums/orientation.enum';

@Injectable({
  providedIn: 'root',
})
export class QrCodeService extends ApiService<QrCode> {
  protected endpoint = 'qr-code';

  constructor(public apiHttp: ApiHttpService) {
    super(apiHttp);
  }

  public download(id: string): Observable<ApiSingleResult<QrCode>> {
    return this.apiHttp.get(`/${this.endpoint}/related/${id}`);
  }

  public downloadSticker(id: string): Observable<ApiSingleResult<QrCode>> {
    return this.apiHttp.get(`/${this.endpoint}/related/${id}/block-production`);
  }

  public downloadA5Sticker(id: string, orientation?: Orientation): Observable<ApiSingleResult<QrCode>> {
    let url = `/${this.endpoint}/related/${id}/a5`;

    if (orientation) {
      url += `-${orientation}`;
    }

    return this.apiHttp.get(url);
  }

  public downloadSign(id: string): Observable<ApiSingleResult<QrCode>> {
    return this.apiHttp.get(`/${this.endpoint}/related/${id}/a4`);
  }
}
