export enum TableListAction {
  delete = 'delete',
  showHistory = 'show-history',
  qrGenerate = 'qr_generate',
  qrGenerateSticker = 'qr_generate_sticker',
  qrGenerateSign = 'qr_generate_sign',
  move = 'move',
  closeBlock = 'close-block',
  printShipment = 'print-shipment',
  inactivate = 'inactivate',
  reinstate = 'reinstate',
  confirmDeparture = 'confirm-departure',
  copy = 'copy',
}
