import { ListOptions } from '@capturum/api';
import { DxpBaseListComponent } from '@core/base/dxp-base-list.class';
import { TableFilter } from '@core/models/table-filter.model';
import { FilterMetadata } from 'primeng/api';
import { FilterType } from '@capturum/ui/api';
import { DateType, InfoTableFilterConfig } from '@shared/modules/info-table-filters/info-table-filter-config.model';
import { DateHelper } from '@core/utils/date.helper';

export class LazyLoadEventUtils {
  /*
   * Returns a new filter metadata object with parsed dates
   * */
  public static parseDateFilters(tableFilters: FilterMetadata, dxpBaseListComponent: DxpBaseListComponent<any>): any {
    if (tableFilters) {
      for (const key in tableFilters) {
        const entry = tableFilters[key];

        if (Array.isArray(entry.value) && entry.value.length === 0) {
          delete tableFilters[key];
        }
      }

      return Object.keys(tableFilters).reduce(
        (previousValue, currentValue) => {
          const tableColumn = dxpBaseListComponent?.columns.find((column) => {
            return column.field === currentValue;
          });

          const isColumnDatePicker = tableColumn && tableColumn.filterType === FilterType.DATEPICKER;

          if (isColumnDatePicker) {
            return {
              ...previousValue,
              [currentValue]: dxpBaseListComponent.parseDateFilter(tableFilters[currentValue] as TableFilter),
            };
          }

          return previousValue;
        },
        { ...tableFilters },
      );
    }

    return tableFilters;
  }

  public static parseDateFiltersReborn(
    apiOptions: ListOptions,
    filters: object,
    filterConfigs: InfoTableFilterConfig[],
  ): Record<string, any> {
    if (filters) {
      return Object.keys(filters).reduce(
        (previousValue, currentValue) => {
          const tableColumn = filterConfigs.find((config) => {
            return config.field === currentValue;
          });
          const isColumnDatePicker = tableColumn?.type === FilterType.DATEPICKER;

          if (isColumnDatePicker && filters[currentValue]) {
            let timezoneManipulation;

            if (tableColumn.hasOwnProperty('dateType')) {
              switch (tableColumn.dateType) {
                case DateType.DATETIME:
                  timezoneManipulation = true;
                  break;
                case DateType.DATE:
                  timezoneManipulation = false;
                  break;
                default:
                  timezoneManipulation = true;
                  break;
              }
            }

            return {
              ...previousValue,
              [currentValue]: DateHelper.toFilterRangeFormat(filters[currentValue], timezoneManipulation).map(
                (date) => {
                  return timezoneManipulation ? date : DateHelper.toDateFormat(date);
                },
              ),
            };
          }

          if (tableColumn?.globalFilter) {
            if (filters[currentValue]) {
              apiOptions.search = [filters[currentValue]];
            } else {
              delete apiOptions?.search;
            }
          }

          return previousValue;
        },
        { ...filters },
      );
    }

    return filters;
  }
}
